<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5 class="m-0">{{ headerTitle }}</h5>
                        <div class="btn btn-primary ms-auto" @click="handleNewItem">
                            <i class="far fa-plus-square"></i>
                            Nuevo Item
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="MenuEditor">
                            <RootItem
                                v-for="(item, i) in menuTree"
                                :key="i"
                                :item="item"
                                @onEdit="handleEditItem"
                                @onToggle="handleToggleItem"
                                @onRemove="handleRemoveItem"
                            >
                            </RootItem>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script>
import {
    defineComponent, onMounted, ref, onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import useSocket from '@/services/useSocket';
import { useSnackbar } from 'vue3-snackbar';
import WindowLayout from '@/layouts/Window.layout.vue';
import useMenuEditor from './services/useMenuEditor';
import RootItem from './components/RootItem.vue';

export default defineComponent({
    components: { WindowLayout, RootItem },
    props: {
        title: String,
        headerTitle: String,
        endPoint: String,
    },
    setup() {
        const snackbar = useSnackbar();
        const { socket } = useSocket();
        const newItemOption = {
            _id: 'newMenuItem',
            id: 'newMenuItem',
            name: 'Crear Menu',
            component: 'MenuItemForm',
            params: {
                title: 'Item del Menu',
                headerTitle: 'Nuevo Item',
            },
        };
        const editItemOption = {
            _id: 'editMenuItem',
            id: 'editMenuItem',
            name: 'Edit Menu Item',
            component: 'MenuItemForm',
            params: {
                title: 'Item del Menu',
                headerTitle: 'Editar Item',
            },
        };
        const menuTree = ref([]);
        const store = useStore();
        const { loadMenuTree, toggleItem, removeItem } = useMenuEditor();

        onMounted(async () => {
            const res = await Promise.all([loadMenuTree()]);
            menuTree.value = [...res[0]];
        });

        const handleNewItem = () => {
            store.dispatch('ContentManager/openWindow', newItemOption);
        };

        const handleEditItem = (item) => {
            store.dispatch('ContentManager/openWindow', {
                ...editItemOption,
                id: `${editItemOption.id}_${item.id}`,
                params: { ...editItemOption.params, item },
            });
        };

        const handleToggleItem = async (item) => {
            await toggleItem(item.id);
        };

        const handleRemoveItem = async (item) => {
            try {
                await removeItem(item.id);
                snackbar.add({
                    type: 'success',
                    text: 'Item eliminado',
                });
            } catch (error) {
                error.map((e) => {
                    snackbar.add({
                        type: 'error',
                        text: e.msg,
                    });
                    return true;
                });
            }
        };

        socket.on('rte:MenuEditor', async ({ action }) => {
            let res;
            switch (action) {
                case 'reload:list':
                    res = await Promise.all([loadMenuTree()]);
                    menuTree.value = [...res[0]];
                    break;
                default:
                    break;
            }
        });

        onBeforeUnmount(() => {
            socket.off('rte:MenuEditor');
        });

        return {
            menuTree,
            handleNewItem,
            handleEditItem,
            handleToggleItem,
            handleRemoveItem,
        };
    },
});
</script>

<style></style>
