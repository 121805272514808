<template>
    <li>
        <div class="d-flex item__header">
            <span class="title">{{item.order}}. {{ item.name }}</span>
            <div class="d-flex options ms-auto">
                <i class="far fa-edit" @click="$emit('onEdit', item)"></i>
                <i
                    class="far"
                    :class="{ 'fa-eye-slash': !item.status, 'fa-eye': item.status }"
                    @click="$emit('onToggle', item)"
                ></i>
                <i class="far fa-trash" @click="$emit('onRemove', item)"></i>
            </div>
        </div>
        <ul class="childrens" v-if="item.childrens && item.childrens.length">
            <SubItem
                v-for="(child, i) in item.childrens"
                :key="i"
                :item="child"
                @onEdit="(item) => $emit('onEdit', item)"
                @onToggle="(item) => $emit('onToggle', item)"
                @onRemove="(item) => $emit('onRemove', item)"
            ></SubItem>
        </ul>
    </li>
</template>

<script>
import { defineComponent } from 'vue';
import SubItem from './SubItem.vue';

export default defineComponent({
    components: { SubItem },
    props: { item: Object },
});
</script>

<style scoped>
.item__header {
    padding-block: 5px;
}
ul.childrens,
li {
    border-top: 1px solid var(--border-shadow);
}

li:first-child,
.childrens li {
    border-top: none;
}

.options {
    column-gap: 8px;
    align-items: center;
}
.options i {
    cursor: pointer;
}
</style>
